import { StatsigClient } from '@statsig/js-client'
import { runStatsigSessionReplay } from '@statsig/session-replay'
import { runStatsigAutoCapture } from '@statsig/web-analytics'

let statsigClient: StatsigClient | null = null

export const statsigInit = async () => {
  statsigClient = new StatsigClient('client-mDgQVI3Dc7routLpE48x6NLSH3o0e9VgGgEkZMz85OH', { userID: 'default-user' }, {
    environment: {
      tier: process.env.NODE_ENV
    }
  })

  // Enable session replay and web analytics
  runStatsigSessionReplay(statsigClient)
  runStatsigAutoCapture(statsigClient)

  await statsigClient.initializeAsync()
  console.debug('Statsig initialized')
}

export const installStatsig = async (userId: string, userEmail: string, orgTier: string, orgEmail: string) => {
  // Create a new client instance with updated user info
  statsigClient = new StatsigClient('client-mDgQVI3Dc7routLpE48x6NLSH3o0e9VgGgEkZMz85OH', {
    userID: userId,
    email: userEmail,
    custom: {
      orgOwnerEmail: orgEmail,
      tier: orgTier
    }
  }, {
    environment: {
      tier: process.env.NODE_ENV
    }
  })

  // Re-enable session replay and web analytics with new client
  runStatsigSessionReplay(statsigClient)
  runStatsigAutoCapture(statsigClient)

  // Initialize the new client
  await statsigClient.initializeAsync()
}

export const logEvent = async (eventName: string, value?: string, metadata?: Record<string, string>) => {
  if (!statsigClient) {
    console.error('Statsig not initialized')
    return
  }

  statsigClient.logEvent(eventName, value, metadata)
  await statsigClient.flush() // Send events immediately
}

export const checkGate = (experimentName: string) => {
  if (!statsigClient) {
    console.error('Statsig not initialized')
    return
  }
  return statsigClient.checkGate(experimentName)
}
