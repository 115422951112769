import { defineStore, acceptHMRUpdate } from 'pinia'
import { getGlobalFeatures, getOrg, subscribeToOrgChanges } from '@/services/databaseHelper'
import { ref, computed } from 'vue'
import { RealtimeChannel, User } from '@supabase/supabase-js'
import { Organization } from '@/types'
import { FeatureFlags } from '@/featureFlags'
import supabase from '@/supabase'

export const useUserStore = defineStore('user', () => {
  const user = ref<User | null>(null)
  const isTrialWithoutCredits = ref(false)
  const org = ref<Organization | null>(null)
  const globalFeatures = ref<FeatureFlags | null>(null)

  const isAppSumo = computed(() => org.value?.tier.startsWith('AppSumo'))
  let subscription: null | RealtimeChannel = null
  const setup = async () => {
    globalFeatures.value = await getGlobalFeatures()

    const { data } = await supabase.auth.getSession()

    if (!data?.session?.user) {
      throw new Error('User not logged in')
    }
    user.value = data.session.user
    org.value = await getOrg(user.value)

    subscription = subscribeToOrgChanges(org.value!.id, org)
  }

  const unspentCredits = computed(() => {
    if (org.value === null) {
      return 0
    }
    console.log('unspent credits', org.value.generation_credits)
    return org.value.generation_credits
  })

  const totalCredits = computed(() => {
    if (org.value!.tier === 'Demo') {
      if (org.value!.generation_credits > 50) {
        return 1000
      } else {
        return 50
      }
    } else if (org.value!.tier === 'Free') {
      return org.value!.total_credits
    } else if (org.value!.tier === 'AppSumo Tier 1') {
      return 250
    } else if (org.value!.tier === 'AppSumo Tier 2' || org.value!.tier === 'AppSumo Tier 3') {
      return org.value!.total_credits ?? 0
    } else if (org.value!.tier === 'Essential') {
      return org.value!.total_credits ?? 500
    } else {
      return org.value!.total_credits
    }
  })
  const monthlyVideoCredits = computed(() => {
    if (org.value!.tier === 'AppSumo Tier 1') {
      return 100
    } else if (org.value?.tier === 'AppSumo Tier 2') {
      return 250
    } else if (org.value?.tier === 'AppSumo Tier 3') {
      return 500
    } else {
      return org.value?.monthly_video_credits
    }
  })

  const videoCredits = computed(() => org.value?.video_credits)
  const showUpgradeButton = computed(() => {
    if (org.value === null) {
      return false
    }
    return !org.value.paid
  })

  const features = computed(() => {
    if (org.value?.features === null) {
      return globalFeatures.value!
    } else {
      return {
        ...globalFeatures.value,
        ...org.value?.features
      }
    }
  })

  const unsubscribe = () => {
    if (subscription) {
      subscription.unsubscribe()
    }
  }

  const maxCustomAppearances = computed(() => {
    const tier = org.value?.tier ?? ''
    if (org.value?.tier === 'Essential' || org.value?.tier === 'Pro') {
      return 10
    } else if (tier === 'AppSumo Tier 2') {
      return 10
    } else if (tier === 'Individual') {
      return 50
    } else if (['Business', 'AppSumo Tier 3'].includes(tier)) {
      return 100
    } else {
      return 100
    }
  })

  const hasCompletedSurvey = computed(() => {
    const isAppSumo = org.value!.tier === 'AppSumo Tier 1' || org.value!.tier === 'AppSumo Tier 2' || org.value!.tier === 'AppSumo Tier 3'
    const isOldUser = (!org.value?.created_at || org.value?.created_at < '2024-07-04T00:00:00.000000+00:00')

    return org.value?.users.reduce((acc: boolean, x) => {
      return acc || Object.keys(x.survey_answers).length > 0
    }, false) || isOldUser || isAppSumo
  })

  const surveyAnswers = computed(() => {
    return org.value?.users.find(x => x.email === org.value?.ownerEmail)?.survey_answers
  })

  const isUnpaid = computed(() => {
    return org.value?.tier === 'Unpaid'
  })

  return {
    setup,
    org,
    user,
    showUpgradeButton,
    features,
    unspentCredits,
    totalCredits,
    isTrialWithoutCredits,
    unsubscribe,
    maxCustomAppearances,
    monthlyVideoCredits,
    videoCredits,
    hasCompletedSurvey,
    isUnpaid,
    surveyAnswers,
    isAppSumo
  }
})

// eslint-disable-next-line
if ((import.meta as any).hot) {
  // eslint-disable-next-line
  (import.meta as any).hot.accept(acceptHMRUpdate(useUserStore, (import.meta as any).hot))
}
