/**
 * main.js
 *
 * Bootstraps Vuetify and other plugins then mounts the App`
 */

// Components
import App from '@/App.vue'
// Composables
import { createApp } from 'vue'
// Plugins
import { registerPlugins } from '@/plugins'
import VueKonva from 'vue-konva'
import { mixpanelInit } from '@/services/mixpanel'
import { statsigInit } from '@/services/statsig'

// Styles
import '@/styles/settings.scss'
import '@/styles/tailwind.css'

// Initialize OpenTelemetry

const app = createApp(App)

app.use(VueKonva, { prefix: 'Konva' })

registerPlugins(app)

mixpanelInit()
statsigInit()

app.mount('#app')
