import axios from 'axios'
import { getAuthToken } from '@/auth'

export async function getAxios (authorization = true) {
  const accessToken = await getAuthToken()
  const instance = axios.create(authorization
    ? {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    : undefined)

  return instance
}

export async function getSlowRequestAxios () {
  const accessToken = await getAuthToken()
  const instance = axios.create({
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    baseURL: import.meta.env.VITE_SLOW_REQUEST_DOMAIN
  })

  return instance
}
