import { defineStore, acceptHMRUpdate } from 'pinia'
import { Frame, PredictionPayload, VideoPayload, Project, GenerationProgress, VideoOption } from '@/types'
import { computed, ComputedRef, ref, reactive, readonly } from 'vue'
import { useAppStore } from '@/store/app'
import { useRoute } from 'vue-router'
import { useUserStore } from '@/store/user'

export interface FrameState {
  generationProgress: GenerationProgress
  videoInProgress: boolean
  imageInProgress: boolean
  inpaintInProgress: boolean
  styleTransferInProgress: boolean
  loadingIndicators: number
  editorImage: string | null
  editorVideo: string | null
  openAIPrompt: PredictionPayload | null
  videoPayload: VideoPayload | null
}

interface FrameStateMap {
  [frameId: string]: FrameState
}

export class FrameStateError extends Error {
  constructor (message: string) {
    super(message)
    this.name = 'FrameStateError'
  }
}

export const getDefaultPayload = (editorImage: string): VideoPayload => {
  const userStore = useUserStore()

  if (userStore.features.RUNWAY) {
    return {
      videoPrompt: '',
      videoDuration: 5,
      sourceImage: editorImage,
      type: 'runway'
    }
  } else {
    return {
      motionBucketId: 127,
      sourceImage: editorImage,
      type: 'svd'
    }
  }
}

export const useEditorStateStore = defineStore('editorState', () => {
  const editorStates = ref<FrameStateMap>({})
  const appStore = useAppStore()

  const frames = computed(() => appStore.currentProject.frames)
  const initializeProjectState = (project: Project) => {
    project.frames.forEach((frame) => {
      initializeFrameState(frame)
    })
  }

  const initializeFrameState = (frame: Frame) => {
    const editorImage = frame.image!
    const editorVideo = frame.video

    editorStates.value[frame.id] = {
      generationProgress: 'DONE',
      videoInProgress: false,
      imageInProgress: false,
      inpaintInProgress: false,
      styleTransferInProgress: false,
      loadingIndicators: 0,
      editorImage: null,
      editorVideo: null,
      openAIPrompt: null,
      videoPayload: null
    }

    if (editorImage) {
      updateEditorImage(frame, editorImage)
    }

    if (editorVideo) {
      updateEditorVideo(frame, editorVideo)
    } else {
      setFallbackEditorVideo(frame)
    }
  }

  const updateFrameState = ({ id: frameId }: { id: string }, updates: Partial<FrameState>) => {
    const frame = frames.value.find((f) => f.id === frameId)
    if (!frame) {
      throw new Error('Frame not found')
    }
    if (!editorStates.value[frame.id]) {
      initializeFrameState(frame)
    }
    editorStates.value[frame.id] = {
      ...editorStates.value[frame.id],
      ...updates
    }
  }

  const getFrameState = ({ id: frameId }: { id: string }): ComputedRef<FrameState> => computed(() => {
    if (!editorStates.value[frameId]) {
      const frame = frames.value.find((f) => f.id === frameId)
      if (!frame) {
        throw new Error('Frame not found')
      }
      initializeFrameState(frame)
    }
    return editorStates.value[frameId]
  })

  const videoModeState = ref(false)
  const videoMode = readonly(videoModeState)

  const enableVideoMode = () => {
    videoModeState.value = true
    smartSwitcher(true)
  }

  const disableVideoMode = () => {
    videoModeState.value = false
    smartSwitcher(false)
  }

  const toggleVideoMode = () => {
    if (videoModeState.value) {
      disableVideoMode()
    } else {
      enableVideoMode()
    }
  }

  const updateEditorImage = ({ id: frameId }: { id: string }, image: string) => {
    const frame = frames.value.find((f) => f.id === frameId)!
    const frameOption = frame.frameOptions.find((fo) => fo.image === image)
    if (!frameOption) {
      throw new Error('Frame option not found')
    }
    if (!frameOption.payload) {
      // assume it's an upload
      frameOption.payload = {
        creativeDirection: '',
        negativePrompt: '',
        shot: 'Medium shot',
        angle: 'Eye level shot'
      }
    }

    if (!frameOption.payload!.scene) {
      frameOption.payload!.scene = (frame as Frame & { scene: string }).scene
    }

    updateFrameState(frame, { editorImage: image, openAIPrompt: frameOption.payload })
  }

  const updateEditorVideo = ({ id: frameId }: { id: string }, video: string) => {
    const frame = frames.value.find((f) => f.id === frameId)!
    const videoOption = frame.videoOptions.find((vo) => vo.url === video)

    if (!videoOption) {
      const fallbackVideoOption: VideoOption = { url: video, payload: { type: 'upload' } }
      updateFrameState(frame, { editorVideo: video, videoPayload: reactive(fallbackVideoOption.payload) })
      console.error('Video option not found for frame', frameId, 'video', video)
    } else {
      updateFrameState(frame, { editorVideo: video, videoPayload: reactive(videoOption.payload) })
    }
  }

  function updateVideoPromptMode(frameId: string, value: 'action'): void
  function updateVideoPromptMode(frameId: string, value: 'dialogue', characterId: string): void

  function updateVideoPromptMode (frameId: string, value: 'action' | 'dialogue', characterId?: string) {
    if (value === 'action') {
      updateFrameState({ id: frameId }, {
        videoPayload: {
          sourceImage: editorStates.value[frameId].editorImage!,
          videoDuration: 5,
          videoPrompt: '',
          type: 'runway'
        }
      })
    } else if (value === 'dialogue') {
      updateFrameState({ id: frameId }, {
        videoPayload: {
          type: 'dialogue',
          text: '',
          characterId,
          sourceImage: editorStates.value[frameId].editorImage!
        }
      })
    }
  }
  const route = useRoute()

  const setFallbackEditorVideo = (frame: Frame) => {
    updateFrameState(frame, {
      editorVideo: frame.image,
      videoPayload: getDefaultPayload(frame.image!)
    })
  }

  const smartSwitcher = async (newVal: boolean) => {
    if (route.name !== 'StoryBoardEditFrame') {
      return
    }

    const frameId = route.params.id as string

    const editorState = getFrameState({ id: frameId })

    const frame = frames.value.find((f) => f.id === frameId)!
    if (newVal) {
      const correspondingVideoOption = frame.videoOptions?.find((vo) => vo.payload.type !== 'upload' && vo.payload.sourceImage === editorState.value.editorImage)
      if (correspondingVideoOption) {
        if (correspondingVideoOption.payload.type === 'upload') {
          setFallbackEditorVideo(frame)
        } else {
          updateEditorVideo(frame, correspondingVideoOption.url)
        }
      } else if (frame.video) {
        updateEditorVideo(frame, frame.video)
      } else if (frame.image) {
        setFallbackEditorVideo(frame)
      } else {
        throw new FrameStateError('No image or video to switch to found')
      }
    } else {
      const correspondingVideoOption = frame.videoOptions?.find((vo) => vo.url === editorState.value.editorVideo)

      if (!correspondingVideoOption) {
        return
      }

      const correspondingFrameOption = frame.frameOptions?.find((fo) => correspondingVideoOption?.payload.type !== 'upload' && fo.image === correspondingVideoOption?.payload.sourceImage)

      if (correspondingFrameOption && correspondingFrameOption.image) {
        updateEditorImage({ id: frameId }, correspondingFrameOption.image)
      } else if (frame.image) {
        updateEditorImage({ id: frameId }, frame.image)
      } else {
        throw new FrameStateError('No image to switch to found')
      }
    }
  }

  return {
    editorStates,
    initializeProjectState,
    initializeFrameState,
    updateFrameState,
    updateEditorImage,
    updateEditorVideo,
    getFrameState,
    updateVideoPromptMode,
    videoMode,
    enableVideoMode,
    disableVideoMode,
    toggleVideoMode
  }
})

// eslint-disable-next-line
if ((import.meta as any).hot) {
  // eslint-disable-next-line
  (import.meta as any).hot.accept(acceptHMRUpdate(useEditorStateStore, (import.meta as any).hot))
}
